<template>
  <div id="team">
    <v-row>
      <v-col
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="offset-lg-2 offset-md-2 offset-sm-0 offset-xs-0"
      >
        <v-card>
          <v-card-text class="position-relative">
            <div class="page-title text-center pt-1">
              <h2
                class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center "
              >
                <span>My Team</span>
              </h2>
              <p>
                Create your team, recruit fighters and compete in tournaments and prize pools.
              </p>
              <div v-if="!team">
                <v-btn
                  color="success"
                  class="mb-1 mt-0 text-center"
                  @click="createTeam()"
                >
                  Create Team
                </v-btn>
              </div>
            </div>
            <div v-if="team">
              <p class="text-center">
                <v-btn
                  outlined
                  color="primary"
                  x-small
                  class="mt-1"
                  @click="randomTeam()"
                >
                  Create Random Team
                </v-btn>
              </p>

              <v-form class="multi-col-validation">
                <v-row class="mb-0 pb-0">
                  <v-col
                    md="8"
                    sm="12"
                    cols="12"
                    class="offset-lg-2 offset-md-2 offset-sm-0"
                  >
                    <h2 class="text-center my-1">
                      Team Name
                    </h2>
                    <v-text-field
                      v-model="team.name"
                      maxlength="25"
                      dense
                      outlined
                    ></v-text-field>
                    <h2 class="text-center my-4">
                      Team Logo
                    </h2>
                    <v-sheet
                      class="mx-auto"
                      max-width="700"
                    >
                      <v-slide-group
                        v-show="isVisible"
                        ref="slideGroup"
                        show-arrows
                        class="slider"
                      >
                        <v-slide-item
                          v-for="(image, imageIndex) in logos"
                          :key="imageIndex"
                          v-slot="{ active}"
                        >
                          <v-avatar
                            rounded
                            size="80"
                            class="team-avatar-wrapper ma-3"
                            color="white"
                          >
                            <img
                              :key="imageIndex"
                              :input-value="active"
                              active-class="success"
                              class=" ml-auto thumbnail logo-slider"
                              color="white"
                              height="80"
                              max-width="80"
                              lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
                              :src="image"
                              @click="team.logo = image"
                            />
                          </v-avatar>
                        </v-slide-item>
                      </v-slide-group>
                    </v-sheet>
                    <hr class="my-4">
                    <p class="text-center my-5 mt-9">
                      <v-avatar
                        v-if="team.logo"
                        rounded
                        size="120"
                        height="120"
                        color="white"
                      >
                        <v-img
                          class="text-center justify-center align-center d-all"
                          height="120"
                          max-width="120"
                          :src="team.logo"
                        />
                      </v-avatar>
                      <br>
                      <v-chip
                        outlined
                        class="mt-2"
                        dark
                      >
                        {{ team.name || 'Team Name' }}
                      </v-chip>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0 mb-4">
                  <v-col
                    md="4"
                    sm="12"
                    cols="4"
                    class="offset-2 offset-md-2 offset-sm-0 mt-0 pt-0"
                  >
                  </v-col>
                  <v-col
                    md="12"
                    sm="12"
                    cols="12"
                    class="mt-0 pt-0"
                  >
                    <p class="text-center">
                      <v-btn
                        outlined
                        color="success"
                        class="mt-0 text-center"
                        @click="saveTeam"
                      >
                        Save Team
                      </v-btn>
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dbFunctions from '@/db'
import nameOptions from '@/data/nameGenerator.json'

export default {
  data() {
    return {
      team: [],
      names: nameOptions,
      logos: '',
      isVisible: true,
    }
  },
  watch: {
    isVisible(val) {
      if (val) this.$refs.slideGroup.setWidths()
    },
  },
  mounted() {
    this.getTeam()
    this.getLogos()
  },

  methods: {
    createTeam() {
      dbFunctions.createTeam(this.$store.state.user.uid)
        .then(teamData => {
          this.getTeam()
          this.$forceUpdate()

          return teamData
        })
    },
    getTeam() {
      dbFunctions.getTeam(this.$store.state.user.uid)
        .then(teamData => {
          this.team = teamData

          return teamData
        })
    },
    saveTeam() {
      const fields = {
        logo: this.team.logo,
        name: this.team.name,
      }
      dbFunctions.updateTeam(this.$store.state.user.uid, fields)
        .then(() => {
          this.$router.push('/my-team', undefined, {shallow: false})
        })
    },
    getLogos() {
      this.loadLogos(require.context('@/assets/images/team-logos/', true, /\.jpg$/))
    },
    loadLogos(r) {
      const logos = []
      r.keys().forEach(key => (logos.push(r(key))))
      this.logos = this.shuffleLogos(logos)
      this.randomTeam()
    },
    shuffleLogos(logoList) {
      const array = logoList
      array.sort(() => Math.random() - 0.5) // rough shuffle not truly random

      return array
    },
    randomTeam() {
      function randomWord(arrayInput) {
        const word = arrayInput[Math.floor(Math.random() * arrayInput.length)]

        return word.charAt(0).toUpperCase() + word.slice(1)
      }

      this.team.name = `${randomWord(this.names.adjectives)} ${randomWord(this.names.nouns)}`
      const logo = this.logos[Math.floor(Math.random() * this.logos.length)]
      this.team.logo = logo
    },

  },
}
</script>

<style lang="scss">
.logo-slider {
  cursor: pointer;
}

span.team-name {
  background: #000 !important;
  color: #ffff;
  padding: 2px;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  width: 120px;
  height: 40px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
  border-radius: 0 0 4px 4px;
  display: inline-block;
  text-align: center;
  margin: auto;

}

span.team-text-wrapper {
  width: 110px;
  height: 30px;
  display: inline-block;
  margin-top: 6px;
  font-size: 12px;
}

span.team-text {
  text-align: center;
  font-size: 14px;
}

.team-avatar-img {
  margin-top: -35px;
  display: inline-block;
}
</style>
